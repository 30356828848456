body {
  margin: 0;
  padding: 0;
  font-family: "Press Start 2P", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: rgb(20, 20, 20);
  color: white;
}

.instructions-box {
  text-align: left;
  max-width: 360px;
  margin: auto;
  margin-bottom: 10px;
}

.loading {
  height: 100vh;
  color: white;
}

a,
a:visited,
a:hover {
  text-decoration: none;
  color: black;
}

.Header {
  margin-bottom: 15px;
}

.Header .top {
  margin-bottom: 20px;
}

.Header .pageTitle {
  color: yellowgreen;
}

.Home .home-logo {
  font-size: 2em;
}

.logo h2,
.logo {
  color: yellowgreen;
}

.Header .logo {
  margin: 0;
}

.ListEntry {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  vertical-align: middle;
}

.ListEntry .entry-value {
  vertical-align: middle;
}

.ListEntry .delete-entry {
  width: 30px;
  height: 30px;
  font-size: 10px;
  padding-left: 7px;
}

.NavButtons {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

.NavButtons .nav-btn {
  color: rgb(20, 20, 20);
  font-size: 14px;
}

.NavButtons .navlinks {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.Home .home-menu-box {
  margin-top: 25px;
  display: -webkit-flex;
  display: flex;
}

.Home .home-menu {
  list-style-type: none;
  padding-left: 0;
  margin: auto;
}

.Home .menu-btn {
  width: 200px;
}

.Create,
.ListView,
.SharedBy {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Create .new-list-container {
  margin: auto;
}

.new-title {
  max-width: 340px;
  margin: auto;
}

.list-view-container {
  margin: auto;
  margin-top: 10px;
}

.ListView .instructions {
  font-size: 0.75rem;
}

.Create .new-list-form {
  max-width: 400px;
  font-size: 11px;
  margin-bottom: 15px;
}

.list-container {
  width: 360px;
  margin: auto;
  text-align: left;
  font-size: 11px;
}

.results-container {
  width: 360px;
  margin: auto; 
  text-align: right;
  font-size: 11px;
}

.results-container .items {
  padding: 0;
}

.results-container .trophy {
  margin-right: 6px;
}

.rank-box {
  width: 5%;
}

.ListView .entries {
  padding: 0;
  text-align: center;
  font-size: 0.8rem;
  line-height: 2;
}

.Create .add-entry,
.Create .new-entry {
  display: inline-block;
  max-width: 300px;
}

.new-entry.clicked {
  background-color: yellowgreen;
}

.Share .urlBox {
  text-align: center;
  margin: auto;
  max-width: 360px;
}

.Share #shareUrl {
  font-size: 9px;
}

.Share .social-buttons a {
  margin: 8px;
}

.footerpadding {
  content: "";
  height: 100px;
}

.is-hidden {
  display: none;
}

.NavButtons .fade {
  opacity: 0.1;
}

.list-entry {
  margin-bottom: 6px;
  background-color: yellowgreen;
  color: #222;
}

.entry-value {
  margin-bottom: 5px;
  background-color: yellowgreen;
  color: #222;
  width: 80%;
  padding-top: 4px;
  text-align: center;
}

.delete-entry {
  margin-top: -2px;
}

.match-entry {
  font-size: 1.6rem;
  margin-bottom: 20px auto;
  height: 50px;
  padding-top: 8px;
}

.match-entry:hover {
  background: white;
}

.winner-entry,
.result-entry {
  display: inline-block;
  margin-left: 8px;
  margin-bottom: 5px;
  background-color: yellowgreen;
  color: #222;
  width: 85%;
  padding-top: 4px;
  text-align: center;
}

.winner-entry {
  background-color: rgba(241, 245, 29, 0.719);
}

.alias-box {
  margin-top: 40px;
  text-align: center;
  color: white;
}

.alias-box .alias {
  margin: 20px;
  font-size: 20px;
  color: white;
}

.change-name {
  text-align: center;
  color: white;
}

.countDown .remaining {
  margin: 20px;
  font-size: 16px;
  color: white;
}

.change-name-form {
  text-align: center;
}

.ListWar .match-container {
  margin: 50px auto;
  max-width: 360px;
  text-align: center;
}

.result-view-container {
  margin: 50px auto;
  max-width: 360px;
  text-align: center;
}

.matchStyle {
  background-color: #9acd32;
  color: #222;
  font-size: 1.6rem;
  margin-bottom: 20px;
  /* height: 50px; */
  padding-top: 8px;
  width: 300px;
  text-align: center;
}

.list-clip-container {
  margin-top: 20px;
}

.header-alias {
  text-align: center;
  display: inline-block;
  margin: 4px 3px 0px 3px;
  color: white;
}
.alias-label {
  color: white;
  font-size: 12px;
}

.little-alias {
  text-align: center;
  display: inline-block;
  font-size: 9px;
  margin: 4px 3px 0px 3px;
  color: white;
}

.little-alias-box {
  margin-top: 3px;
  text-align: center;
}

.mylist-clips {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

.results-lists {
  list-style-type: none;
  padding: 0;
}

.instructions, .instructions-label {
  font-size: 12px;
}

.instructions-label {
  color: yellowgreen;
  margin: 0;
  padding: 0;
}

.change-name-box {
  max-width: 360px;
  margin: auto;
}

.copied {
  -webkit-animation: fadein 400ms;
          animation: fadein 400ms; 
}

@-webkit-keyframes fadein {
  0%   { opacity: 0; -webkit-transform: scale(0.4); transform: scale(0.4) }
  100% { opacity: 1; -webkit-transform: scale(1); transform: scale(1) }
}

@keyframes fadein {
  0%   { opacity: 0; -webkit-transform: scale(0.4); transform: scale(0.4) }
  100% { opacity: 1; -webkit-transform: scale(1); transform: scale(1) }
}

@media (max-width: 400px) {
  .Header .logo {
    font-size: 20px;
  }

  .Home .logo {
    font-size: 24px;
  }

  /* .instructions, .instructions-label {
    font-size: 8px;
  } */
}

